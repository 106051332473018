$main-theme-color: #cfbe71;
$button-text: #fff;
$body-text-color: #121212;
$main-light-color: #fff;
$main-light-gray: #f5f5f5;
$main-gray: #222;
$main-gray: #aaa;
$theme-gray-base: #222;
$main-dark-color: #000;
$transparent: rgba(255,255,255,0);

$theme-link-color: $main-theme-color;
$theme-link-hover-color: $main-theme-color;

// P A D D I N G S
$padding-base: 15px;
$padding-medium: 30px;
$padding-large: 60px;
$padding-x-large: 90px;

// M A R G I N S 
$margin-base: 15px;
$margin-medium: 30px;
$margin-large: 60px;
$margin-x-large: 90px;


// F O N T S
$main-font:'Philosopher', sans-serif;
$main-font-secondary: 'Lato', sans-serif;
$main-font-script: 'Alex Brush', cursive;

// B O R D E R  R A D I U S
$main-border-radius: 5px;

// T R A N S I T I O N S
$main-transition:  all 300ms ease-in-out;

 

 
 

